/* HomePage.css */

.fixed-image {
    width: 100%;
    height: 250px;
    object-fit: cover; /* This ensures the image covers the area without stretching */
}
.expandable-box {
    height: 450px; /* Adjust the height as needed */
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.expandable-box .card-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px; /* Optional: Adjust spacing below the title */
} 
.expandable-box .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card-text {
    height: auto; /* Adjust this if needed to ensure text is displayed properly */
}
.responsive-image {
    width: 200px; /* Default size for larger screens */
    height: auto;
  }
  
.responsive-logo {
width: 400px; /* Default size for larger screens */
height: auto;
}
  
@media (max-width: 768px) {
  .left-image {
    width: 100px; /* Smaller size for mobile */
    margin-top: 10px; /* Add space below c3v60 logo */
    order: 2; /* Position it below c3v60 logo */
  }

  .responsive-logo {
    width: 200px; /* Adjust c3v60 logo size for mobile */
  }

  .right-image {
    display: none; /* Hide the right image on mobile */
  }
}
  