.details-container {
    padding: 20px;
  }
  
  .details-text {
    padding: 20px;
  }
  
  img {
    width: 100%;
    height: auto;
    max-width: 500px;
    margin: 0 auto;
    display: block;
  }
  
  @media (max-width: 768px) {
    .details-container {
      text-align: center;
    }
  }
  