/* ModulesPage.css */

/* Ensure all boxes are of equal height within a row */
.equal-height-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid #ddd; /* Light gray border */
    padding: 15px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
  }
  
  /* Specific styling for Production and Accounting & Finance boxes */
  .equal-height-box.d-flex {
    flex-direction: row;
  }
  
  .equal-height-box img {
    width: 100%;
    height: auto;
    margin-top: 15px;
    object-fit: cover;
  }
  
  /* Space between the title and content */
  .equal-height-box h3,
  .equal-height-box h4 {
    margin-bottom: 10px;
  }
  
  /* Ensure images fill the available space */
  .equal-height-box img {
    width: 100%;
    height: auto;
    margin-top: 15px;
    object-fit: cover; /* Ensures the image covers the space without distortion */
  }
  
  /* Handle margin between rows */
  .mt-4 {
    margin-top: 1.5rem !important;
  }
  
  /* Optional: Make text in equal-height-box responsive and aligned */
  .equal-height-box p {
    margin-top: auto;
    text-align: justify;
  }
  
  /* Adjust outline for boxes with both text and image */
  .equal-height-box.d-flex {
    border: 2px solid #aaa;
    padding: 20px;
  }
  