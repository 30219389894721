/* General navbar styles */
.custom-navbar {
  background-color: #E0E0E0;
}

/* Custom button styles */
.custom-btn {
  background-color: #1520bf;
  border-color: #1520bf;
}

/* Styles for small screens (mobile) */
@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #E0E0E0;
    padding: 1rem;
    z-index: 1000;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .nav-item {
    margin-bottom: 0.5rem;
  }

  /* Center the logo on small screens */
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Ensure the button stays on the right */
  .custom-btn {
    margin-left: auto;
  }
}

/* Styles for large screens (desktop) */
@media (min-width: 992px) {
  .navbar-brand {
    margin-right: 1rem;
  }

  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-nav {
    flex-direction: row;
    margin-left: 1rem;
  }

  .nav-item {
    margin-right: 1rem;
  }

  /* Ensure proper alignment of navbar items */
  .navbar > .container {
    display: flex;
    align-items: center;
  }

  /* Push the button to the right */
  .custom-btn {
    margin-left: auto;
  }
}

/* Active link styles */
.nav-link.active {
  font-weight: bold;
}

/* Additional styles for better spacing and alignment */
.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-brand img {
  max-height: 50px;
  width: auto;
}