/* Styling for the privacy policy container */
.privacy-policy-page {
  padding: 20px;
  padding-bottom: 50px; /* Ensure space at the bottom of the page */
}

/* Privacy policy content box */
.privacy-content {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 0 auto; /* Center the content */
}

.privacy-content h3,
.privacy-content h4 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Indent the paragraph and remove bottom margin */
.privacy-content p.indent-paragraph {
  margin-bottom: 0; /* No space between the paragraph and list */
  padding-left: 30px; /* Indent the paragraph to align with the section title */
}

/* Ensure list is aligned properly under the paragraph */
.no-space-list {
  padding-left: 50px; /* Align the bullets under the paragraph */
  list-style-type: disc; /* Bullet points */
  margin-top: 0; /* No space between the list and paragraph */
  margin-bottom: 0; /* No space between the list and the next paragraph */
}

/* Ensure no extra space between list items */
.no-space-list li {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
}

/* Ensure no space between the list and the next paragraph */
.privacy-content p + ul {
  margin-top: 0;
  margin-bottom: 0;
}

.privacy-content ul + p {
  margin-top: 0;
}
/* Custom indent list with different bullet style */
.second-indent-list {
  padding-left: 60px; /* Extra indentation for the custom list */
  list-style-type: circle; /* Different bullet style, e.g., circle */
  margin-top: 0px; /* Space above this custom list */
  margin-bottom: 0px; /* Space below this custom list */
}

.second-indent-list li {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
}

  /* Styling for the announcements container */
  .announcements-page {
    padding: 20px;
    padding-bottom: 50px; /* Add padding at the bottom to avoid footer overlap */
  }
  
  /* Each announcement box styling */
  .announcement-box {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    height: auto; /* Allow the height to adjust dynamically */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden; /* Ensure content stays within the box */
  }
  
  /* Announcement image */
  .announcement-image {
    width: 100%;
    height: 52px; /* Adjust image height as needed */
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  /* Show More/Show Less button */
  .show-more-btn {
    margin-top: 15px;
    font-weight: bold;
    color: #007bff;
    cursor: pointer;
    background: none;
    border: none;
  }
  
  /* Ensure footer stays at the bottom of the page */
  footer {
    margin-top: 50px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .announcement-box {
      height: auto; /* Ensure it adjusts on small screens too */
    }
  }
  /* Styling for the announcements container */
  .contact-us-page {
    padding: 20px;
    padding-bottom: 50px; /* Add padding at the bottom to avoid footer overlap */
  }

  /* Custom button styling */
.demo-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.demo-buttons .btn {
  width: auto;
}

 